import React from 'react';
import './App.css';
import {faLinkedin} from '@fortawesome/free-brands-svg-icons';
import {faBrain, faUserGroup, faHashtag} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './style/App.css';
import './style/Buttons.css';
import './style/Cards.css';
import logo from './media/unki_logo.png';
import EPFL from './media/logos/EPFL.png';
import AWS from './media/logos/AWS.png';
import Blaze from './media/logos/Blaze.png';
import EHL from './media/logos/EHL.png';
import Hotellerie from './media/logos/Hotellerie.png';
import Microsoft from './media/logos/Microsoft.png';
import LaForge from './media/logos/LaForge.png';
import home_image from './media/home-image.jpg';
import dashed_line from './media/dashed_dark_green.png';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import BackToTop from './components/BackToTop';

function App() {
  return (
    <div className="App">
        <div className='header'>
          <div className='logo'>
            <img src={logo} alt="Unki logo"></img>
          </div>
          <div className='menu'>
            <a id="about-menu" href="#about-section">About</a>
            <a href="#support-section">Supporters</a>
          </div>
          <div className='social-media'>
            <a href="https://www.linkedin.com/company/unki/" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faLinkedin} size='xl'></FontAwesomeIcon></a>
          </div>
        </div>
        <div className='home'>
          <div className='home-content'>
            <h1>The future of trip planning is here.</h1>
            <p>The perfect tool to plan your tailor-made itinerary</p>
            <a href="#about-section"><Button className="primary" variant="contained">Discover more</Button></a>
          </div>
          <img src={home_image} alt="People planning a trip together." />
        </div>
        <div id='about-section' className='about'>
          <div className='about-content'>
            <h1>Your all-in-one trip planner</h1>
            <p>No need to visit thousands of pages to plan your trip... Everything is here !</p>
          </div>
          <div className='card-content'>
            <Card className='card'>
              <CardContent>
                <FontAwesomeIcon icon={faBrain} size='2xl'></FontAwesomeIcon>
                <h2>AI-assisted travel planning</h2>
                <p>Unki delves deep into understanding what each traveler truly desires. Whatever you are looking for, Unki curates the perfect itinerary for you.</p>
              </CardContent>
            </Card>
            <Card className='card'>
              <CardContent>
              <FontAwesomeIcon icon={faUserGroup} size='2xl'></FontAwesomeIcon>
                <h2>Group planning & coordination</h2>
                <p>Share your itinerary with your friends and family. Work together to achieve the perfect trip that suits you all.</p>
              </CardContent>
            </Card>
            <Card className='card'>
              <CardContent>
                <FontAwesomeIcon icon={faHashtag} size='2xl'></FontAwesomeIcon>
                <h2>Connected to social media</h2>
                <p>With Unki, it is as easy as sharing a post from social media into a WhatsApp or Telegram conversation. It all starts there.</p>
              </CardContent>
            </Card>
          </div>
          <img src={dashed_line} alt="People planning a trip together." />
          <a href="#support-section"><Button className='primary' variant='contained'>See who supports us</Button></a>
        </div>
        <div id='support-section' className='support'>
          <div className='support-content'>
            <h1>They support Unki</h1>
            <p>We already have some early believers</p>
          </div>
          <div className='supporters'>
            <img className='flex-left' src={EPFL} alt="EPFL" />
            <img className='flex-center' src={EHL} alt="EHL" />
            <img className='flex-right' src={Blaze} alt="Blaze" />
            <img className='flex-left' src={Hotellerie} alt="Hotellerie" />
            <img className='flex-center' src={LaForge} alt="LaForge" />
            <img className='flex-right' src={AWS} alt="AWS" />
            <img className='flex-left' src={Microsoft} alt="Microsoft" />
            
          </div>
         <a href="mailto:contact@unki.ch"><Button id="dark-button" className="primary" variant="contained" >Become a supporter</Button></a>
        </div>

        <div className='footer'>
          <img id="footer-image" src={home_image} alt="People planning a trip together." />
          <div className='overlay-background'></div>
          <div className='top-line'>
            <div className='logo'>
              <img src={logo} alt="Unki logo"></img>
            </div>
            <p>Feel free to get in touch with us to know more abouut the project.</p>
          </div>
          <div className='bottom-line'>
            <p>Copyright © 2024 Unki</p>
            <div className='social-media'>
              <a href="mailto:contact@unki.ch"><Button className='primary' variant='contained'>Get in touch</Button></a>
              <a href="https://www.linkedin.com/company/unki/" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faLinkedin} size='xl'></FontAwesomeIcon></a>
            </div>
          </div>
        </div>
        <BackToTop/>
      </div>
  );
}

export default App;
