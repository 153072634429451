import React, { useState, useEffect } from 'react';
import '../style/BackToTop.css';
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faArrowUp} from '@fortawesome/free-solid-svg-icons';

const BackToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Function to toggle visibility based on page scroll
  const toggleVisibility = () => {
    if (window.scrollY > 20) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Function to scroll to the top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);

    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  return (
    <>
      {isVisible && (
        <Button className='back-to-top' variant="contained" onClick={scrollToTop}>
            <FontAwesomeIcon icon={faArrowUp} size='2x' color='white'></FontAwesomeIcon>
        </Button>
      )}
    </>
  );
};



export default BackToTop;
